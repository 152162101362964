export default {
    // TESTNET
    // MOCK_TOKEN: "0x56768D2DD02D64094d7BA2f0D8e47FeF65cc6de6",
    // STEP_APP_STAKING: "0xA13F939Af56E79ABf81A07b256788629484e9e22",
    // STEP_APP_CALCULATOR: "0x77860967c8Cb7B82D85E5bF30ef767Bc75A756CB",
    // STEP_APP_DISTRIBUTOR: "0x825198df206dDAc01Bb814549dFC961096394453",
    // MERKLE_DISTRIBUTOR1: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR2: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR3: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR4: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR5: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR6: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR7: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR8: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR9: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR10: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR11: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR12: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR13: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR14: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR15: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR16: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR17: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR18: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR19: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR20: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR21: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR22: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR23: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR24: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR25: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR26: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR27: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR28: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR29: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR30: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR31: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR32: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR33: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR34: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR35: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR36: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR37: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR38: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR39: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR40: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR41: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR42: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR43: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR44: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR44: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR45: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR46: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR47: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR48: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR49: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR50: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR51: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",
    // MERKLE_DISTRIBUTOR52: "0xF5c6Afb0dc0FC6EfA1D4bd73fa463CAF49ce1690",

    // MAINNET
    // MOCK_TOKEN: "0x714f020C54cc9D104B6F4f6998C63ce2a31D1888",
    STEP_APP_STAKING: "0x1611018A75e19851e3686dDF167FD357Dde252E0",
    STEP_APP_CALCULATOR: "0x51db680098E2388b8B591f36D9d67722Cf279C5E",
    STEP_APP_DISTRIBUTOR: "0xAd2150e6032adb3Cd4282580576335E67f17a1c1",
    MERKLE_DISTRIBUTOR1: "0xE4E9553766f1b2A56e01c047E6289ED3991a94a8",
    MERKLE_DISTRIBUTOR2: "0xAc1cCB1f292b68148Dc84eD3b36fBE0514d2acBf",
    MERKLE_DISTRIBUTOR3: "0xAB0b52d175B8A90f47BaB6F5Dc5A0bbA2eF8D4A7",
    MERKLE_DISTRIBUTOR4: "0x37b6765536a20B6922d2499155860408A390E1A0",
    MERKLE_DISTRIBUTOR5: "0xCB07fdE8F26510aB09D6e65769b942E9db78dff3",
    MERKLE_DISTRIBUTOR6: "0xd5A241396B533E09Fc67595B451A1e6D2d46e159",
    MERKLE_DISTRIBUTOR7: "0xFf93b95CbCc1c85dc7303EAFb0aAB89A91E49d87",
    MERKLE_DISTRIBUTOR8: "0x7AdBc3A194244cC4c15DDb4738Aa687d57E1577E",
    MERKLE_DISTRIBUTOR9: "0x2E4832Cc8B932e5c7B4097ec8C5B16675dcaEeFD",
    MERKLE_DISTRIBUTOR10: "0x4Cee598a79af5F9935702Bd1cf7A44cD08c25cb5",
    MERKLE_DISTRIBUTOR11: "0xc44cA8eCD9a5436F4905Ff1f821fc4F34DD68Fe0",
    MERKLE_DISTRIBUTOR12: "0x629ab8420dE1A91F903E3618deF509D2C470876a",
    MERKLE_DISTRIBUTOR13: "0xFDdDd8698Cf3D4922f87db1E2009B8507cC2F887",
    MERKLE_DISTRIBUTOR14: "0x4ea0bBEeD292D1E0b335e00e89f0374ff31739F8",
    MERKLE_DISTRIBUTOR15: "0x9B74857CEf5318C970C0163f888766BB7F30f0Ca",
    MERKLE_DISTRIBUTOR16: "0x8AC5015402B85482d8FD2E0944d36a0Bc9Be7533",
    MERKLE_DISTRIBUTOR17: "0x459d72df6b3bfa71a8499a2Ac65E8C58335543E1",
    MERKLE_DISTRIBUTOR18: "0xf22Bc90D0701Fe84b94E870702470836090fA5c6",
    MERKLE_DISTRIBUTOR19: "0x52e37B6B7d9Bc5309141F0842D2d88517433E461",
    MERKLE_DISTRIBUTOR20: "0xb8C2D5EED3dD41b2658F17dAAb90C346BD4Eb777",
    MERKLE_DISTRIBUTOR21: "0x62f280D60820b035eB3829eEC8F96B1A4b71f169",
    MERKLE_DISTRIBUTOR22: "0xa25a0AbF98e71de6b2EFe60Da15003e5C90Fe2Ef",
    MERKLE_DISTRIBUTOR23: "0x953acc4B0789C3774a8fFF4D25FD4102bC85b71e",
    MERKLE_DISTRIBUTOR24: "0x6147332F2a1a9Eea64cb731071264Acb9B72D81C",
    MERKLE_DISTRIBUTOR25: "0x37B921805A309e8Ca596d6D8657CCE5730856F1C",
    MERKLE_DISTRIBUTOR26: "0x8B0CA63a3E5462f21AcE9E2E99472E390DA098d1",
    MERKLE_DISTRIBUTOR27: "0x5f78FC5F67aEFA30F842593234B4719AAaeDfD97",
    MERKLE_DISTRIBUTOR28: "0x51408860f02Df877420CfEe3A7846007BD27c036",
    MERKLE_DISTRIBUTOR29: "0xff6DC3aCad9A8192eb46EdCC1A9F7A85D44299b7",
    MERKLE_DISTRIBUTOR30: "0x1CBE46CeeFb0728449583B22D5cE7AA0B0850C5E",
    MERKLE_DISTRIBUTOR31: "0xE42515D5614D991Ca110f5408b046Ed9f852D5b1",
    MERKLE_DISTRIBUTOR32: "0xCccbDB6E0D8623c32A89B453667506D77275557A",
    MERKLE_DISTRIBUTOR33: "0xeF4C4a8c2BB43Cb8DF357f09315DddA195268362",
    MERKLE_DISTRIBUTOR34: "0x30390D889D96Ef4ee72870f86df2D5d2120Fbcf2",
    MERKLE_DISTRIBUTOR35: "0x579cd89724c6817999Ec9F60F003cD590bDeD282",
    MERKLE_DISTRIBUTOR36: "0xb5Be9c23949480Ac1705508FF19bC0E5d69a6Cd2",
    MERKLE_DISTRIBUTOR37: "0x1d3A9e3048B727999aB67221a0e67e765A2E73eA",
    MERKLE_DISTRIBUTOR38: "0xd3443F534495eA613D5Ebe34F6408BA660b0549e",
    MERKLE_DISTRIBUTOR39: "0xcD761B8358b347CAe755a7D438Ee865916cF919A",
    MERKLE_DISTRIBUTOR40: "0x2D9e5187dDfe458a59B5567eCB7B20C8bc6D7319",
    MERKLE_DISTRIBUTOR41: "0xb37Bb05eA6929650FF6701b4E1D6a3A9F50AAA58",
    MERKLE_DISTRIBUTOR42: "0x2e5208c05a9CE0472477A03731d2b74784F68A7f",
    MERKLE_DISTRIBUTOR43: "0x082d3cDcC41c615E155D9A50f936C4355A9Ca47D",
    MERKLE_DISTRIBUTOR44: "0x5c140Db5025ec3611bD1D761e4aF828219092C64",
    MERKLE_DISTRIBUTOR45: "0x568a301b2012Cc1C06F5D37D6E09C6Ac46440d80",
    MERKLE_DISTRIBUTOR46: "0xdc255fD3c4db3bCB900eFA059f6B77B1577A37B4",
    MERKLE_DISTRIBUTOR47: "0xF58572d3FB0Fb55eb8d5d1047aD0D81D334Cac68",
    MERKLE_DISTRIBUTOR48: "0x6d47670b201b9A55880E42ccD75d5Bb0Bf26ddBe",
    MERKLE_DISTRIBUTOR49: "0xedd992dAa77Dc44Da91e3a7a41FbF3379F6EeF04",
    MERKLE_DISTRIBUTOR50: "0xf3B04fE5796161EBEEd309B548319bF4D835eaa7",
    MERKLE_DISTRIBUTOR51: "0x75E8Af1d99e8b7f7732d24e5AcC29D63B1D8553f",
    MERKLE_DISTRIBUTOR52: "0xA9b44DBBEd44aab6D95628B591b172E6584eAb17",
    MERKLE_DISTRIBUTOR53: "0x8006835DA476C81326942F14Ebf889398Ac4bC05",
    MERKLE_DISTRIBUTOR54: "0x3Dc25812d90f7b9945061040E0f2991A27237dfc",
    MERKLE_DISTRIBUTOR55: "0xD9B8b9E6a68d903DE47087776408BcFA6069EE75",
    MERKLE_DISTRIBUTOR56: "0x389CFc41CCFd62c6f2358b92c03756658022276d",
    MERKLE_DISTRIBUTOR57: "0x9Cd9E551E5029Eac751Eb8538487Ed7442c1a2B5",
    MERKLE_DISTRIBUTOR58: "0x0f7b8e87e0d5E6F234Afb3D827CCD23bdC6F2cad",
    MERKLE_DISTRIBUTOR59: "0x171AF7003C2dD5A044F42E2757178d678943af4d",
    MERKLE_DISTRIBUTOR60: "0x9eB00D5F86208F6024B78e781e49DA968280C236",
    MERKLE_DISTRIBUTOR61: "0x2538974F24a5d2E60d348c6638BA53C878a4E995",
    MERKLE_DISTRIBUTOR62: "0xB4a8996b528e6278b771792EE013D2006393E5A0",
    MERKLE_DISTRIBUTOR63: "0x44C2dB2288D9e8D3fd613048cDf371C51154Fd69",
    MULTICALL: "0x176CcFFbAB792Aaa0da7C430FE20a7106d969f66",
};
