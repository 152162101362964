import ADDRESSES from "./address";

export const winnersUrls = [
    "https://drive.google.com/file/d/1VXDoHSKghMwqI_8iAba3nipjnDM-NSHT/view",
    "https://drive.google.com/file/d/1N62C-yqz2GTDL_kECPUnkUWk9WupAY99/view?usp=sharing",
    "https://drive.google.com/file/d/1aM_ubujpS52WJ1_G7x2EZkdmAOsGf6KH/view?usp=sharing",
    "https://drive.google.com/file/d/1FGmFiFjZ4L7Mz9X0_NjZbrIlBohfUOE2/view?usp=sharing",
    "https://drive.google.com/file/d/1NNixq53hvRsWPlvLPMr5yjA8dp4x6CkK/view?usp=sharing",
    "https://drive.google.com/file/d/1u8pzmbwvJ8Ol6RbaqA5USu28qmMM2M86/view?usp=sharing",
    "https://drive.google.com/file/d/14mqRuGEjD9q8oXkRk3h5BVGqQoUrmJIP/view?usp=sharing",
    "https://drive.google.com/file/d/1jDZkolsOzKjJ0KnGOWoURMUfKkTS5nGU/view?usp=sharing",
    "https://drive.google.com/file/d/1Vs-t64vPPeo4VPpl4xIdqTRrOenZFn6T/view?usp=sharing",
    "https://drive.google.com/file/d/10sT3H35iCe-DeCquS50a_EzTcoszkmt1/view?usp=sharing",
    "https://drive.google.com/file/d/1wVomU18OI_MolYlK0Qa6dwgUs4BwllGo/view?usp=sharing",
    "https://drive.google.com/file/d/1h8ZYjjz43vzh6FzkEQ3yEpeKqiS9ZddT/view?usp=sharing",
    "https://drive.google.com/file/d/1RccuO9GJvdNyE_Qn9542qqjFEoNl7218/view?usp=sharing",
    "https://drive.google.com/file/d/1oG10hox6dDl2AFK5qpFPJtCKH2ZObFsw/view?usp=sharing",
    "https://drive.google.com/file/d/1h5L7fRPzWCET51yYOOC7Lm1JluAU-RA9/view?usp=sharing",
    "https://drive.google.com/file/d/1dWApmVtEFAmmKxoKJRwMQnyFrjB3K4zO/view?usp=sharing",
    "https://drive.google.com/file/d/1XV0agYrVpVjl3aacBLKTzCnMnaFhM65i/view?usp=sharing",
    "https://drive.google.com/file/d/1NB2beNnD3vx7-0-uUDP38DCAZ0izUhGf/view?usp=sharing",
    "https://drive.google.com/file/d/18F1FOYw_8pfJWb8uUXC9Kmjl7hu_JZ8A/view?usp=sharing",
    "https://drive.google.com/file/d/1GV8GAztKFMg21N3r6oEWgrMfSK1QGuto/view?usp=sharing",
    "https://drive.google.com/file/d/13yE6E1UeL02xeaKNWc1CynlWPQAP3sac/view?usp=sharing",
    "https://drive.google.com/file/d/1236x_6OnF6oaKB68hIbLhwBEJcT71_mW/view?usp=sharing",
    "https://drive.google.com/file/d/1OJSih0LAGapBO3ItsfIyvVJj8cXFG2Fn/view?usp=sharing",
    "https://drive.google.com/file/d/1inyOsJSHLALNUw2usiCMAWuVG4v_lS2v/view?usp=sharing",
    "https://drive.google.com/file/d/196jEeIljd086eX4cnS2IgFJTyLfJCVHC/view?usp=sharing",
    "https://drive.google.com/file/d/154ehe3atB28N8K-zwdFG_WDpd5T4Hj84/view?usp=sharing",
    "https://drive.google.com/file/d/1vmkxvO7Em9E4HgswAU_WczR_BuCK_yZ-/view?usp=sharing",
    "https://drive.google.com/file/d/1dCOOowobUISBu6cznO6iVH5XnKydU5g6/view?usp=sharing",
    "https://drive.google.com/file/d/1Aa0uhne9wQEdzecd-2O6Uq33cfOfd2HH/view?usp=sharing",
    "https://drive.google.com/file/d/1KD68MKgqtjg7enWcrY3OXowijugcM1T5/view?usp=sharing",
];

export const snapshotDates = [
    "20 MAY 12:00 UTC",
    "3 JUNE 12:00 UTC",
    "17 JUNE 12:00 UTC",
    "1 JULY 12:00 UTC",
    "15 JULY 12:00 UTC",
    "29 JULY 12:00 UTC",
    "12 AUGUST 12:00 UTC",
    "26 AUGUST 12:00 UTC",
    "9 SEPTEMBER 12:00 UTC",
    "23 SEPTEMBER 12:00 UTC",
    "7 OCTOBER 12:00 UTC",
    "21 OCTOBER 12:00 UTC",
    "4 NOVEMBER 12:00 UTC",
    "18 NOVEMBER 12:00 UTC",
    "2 DECEMBER 12:00 UTC",
    "16 DECEMBER 12:00 UTC",
    "30 DECEMBER 12:00 UTC",
    "13 JANUARY 12:00 UTC",
    "27 JANUARY 12:00 UTC",
    "10 FEBRUARY 12:00 UTC",
    "24 FEBRUARY 12:00 UTC",
    "10 MARCH 12:00 UTC",
    "24 MARCH 12:00 UTC",
    "7 APRIL 12:00 UTC",
    "21 APRIL 12:00 UTC",
    "5 MAY 12:00 UTC",
    "19 MAY 12:00 UTC",
    "2 JUNE 12:00 UTC",
    "16 JUNE 12:00 UTC",
    "30 JUNE 12:00 UTC",
    "14 JULY 12:00 UTC",
    "28 JULY 12:00 UTC",
    "11 AUGUST 12:00 UTC",
    "25 AUGUST 12:00 UTC",
    "8 SEPTEMBER 12:00 UTC",
    "22 SEPTEMBER 12:00 UTC",
    "6 OCTOBER 12:00 UTC",
    "20 OCTOBER 12:00 UTC",
    "3 NOVEMBER 12:00 UTC",
    "17 NOVEMBER 12:00 UTC",
    "1 DECEMBER 12:00 UTC",
    "15 DECEMBER 12:00 UTC",
    "29 DECEMBER 12:00 UTC",
    "12 JANUARY 12:00 UTC",
    "26 JANUARY 12:00 UTC",
    "9 FEBRUARY 12:00 UTC",
    "23 FEBRUARY 12:00 UTC",
    "8 MARCH 12:00 UTC",
    "22 MARCH 12:00 UTC",
    "5 APRIL 12:00 UTC",
    "19 APRIL 12:00 UTC",
    "3 MAY 12:00 UTC",
    "17 MAY 12:00 UTC",
    "31 MAY 12:00 UTC",
    "14 JUNE 12:00 UTC",
    "28 JUNE 12:00 UTC",
    "12 JULY 12:00 UTC",
    "26 JULY 12:00 UTC",
    "09 AUGUST 12:00 UTC",
    "23 AUGUST 12:00 UTC",
    "06 SEPTEMBER 12:00 UTC",
    "20 SEPTEMBER 12:00 UTC",
    "04 NOVEMBER 12:00 UTC",
];
export const contractAddresses = [
    ADDRESSES.MERKLE_DISTRIBUTOR1,
    ADDRESSES.MERKLE_DISTRIBUTOR2,
    ADDRESSES.MERKLE_DISTRIBUTOR3,
    ADDRESSES.MERKLE_DISTRIBUTOR4,
    ADDRESSES.MERKLE_DISTRIBUTOR5,
    ADDRESSES.MERKLE_DISTRIBUTOR6,
    ADDRESSES.MERKLE_DISTRIBUTOR7,
    ADDRESSES.MERKLE_DISTRIBUTOR8,
    ADDRESSES.MERKLE_DISTRIBUTOR9,
    ADDRESSES.MERKLE_DISTRIBUTOR10,
    ADDRESSES.MERKLE_DISTRIBUTOR11,
    ADDRESSES.MERKLE_DISTRIBUTOR12,
    ADDRESSES.MERKLE_DISTRIBUTOR13,
    ADDRESSES.MERKLE_DISTRIBUTOR14,
    ADDRESSES.MERKLE_DISTRIBUTOR15,
    ADDRESSES.MERKLE_DISTRIBUTOR16,
    ADDRESSES.MERKLE_DISTRIBUTOR17,
    ADDRESSES.MERKLE_DISTRIBUTOR18,
    ADDRESSES.MERKLE_DISTRIBUTOR19,
    ADDRESSES.MERKLE_DISTRIBUTOR20,
    ADDRESSES.MERKLE_DISTRIBUTOR21,
    ADDRESSES.MERKLE_DISTRIBUTOR22,
    ADDRESSES.MERKLE_DISTRIBUTOR23,
    ADDRESSES.MERKLE_DISTRIBUTOR24,
    ADDRESSES.MERKLE_DISTRIBUTOR25,
    ADDRESSES.MERKLE_DISTRIBUTOR26,
    ADDRESSES.MERKLE_DISTRIBUTOR27,
    ADDRESSES.MERKLE_DISTRIBUTOR28,
    ADDRESSES.MERKLE_DISTRIBUTOR29,
    ADDRESSES.MERKLE_DISTRIBUTOR30,
    ADDRESSES.MERKLE_DISTRIBUTOR31,
    ADDRESSES.MERKLE_DISTRIBUTOR32,
    ADDRESSES.MERKLE_DISTRIBUTOR33,
    ADDRESSES.MERKLE_DISTRIBUTOR34,
    ADDRESSES.MERKLE_DISTRIBUTOR35,
    ADDRESSES.MERKLE_DISTRIBUTOR36,
    ADDRESSES.MERKLE_DISTRIBUTOR37,
    ADDRESSES.MERKLE_DISTRIBUTOR38,
    ADDRESSES.MERKLE_DISTRIBUTOR39,
    ADDRESSES.MERKLE_DISTRIBUTOR40,
    ADDRESSES.MERKLE_DISTRIBUTOR41,
    ADDRESSES.MERKLE_DISTRIBUTOR42,
    ADDRESSES.MERKLE_DISTRIBUTOR43,
    ADDRESSES.MERKLE_DISTRIBUTOR44,
    ADDRESSES.MERKLE_DISTRIBUTOR45,
    ADDRESSES.MERKLE_DISTRIBUTOR46,
    ADDRESSES.MERKLE_DISTRIBUTOR47,
    ADDRESSES.MERKLE_DISTRIBUTOR48,
    ADDRESSES.MERKLE_DISTRIBUTOR49,
    ADDRESSES.MERKLE_DISTRIBUTOR50,
    ADDRESSES.MERKLE_DISTRIBUTOR51,
    ADDRESSES.MERKLE_DISTRIBUTOR52,
    ADDRESSES.MERKLE_DISTRIBUTOR53,
    ADDRESSES.MERKLE_DISTRIBUTOR54,
    ADDRESSES.MERKLE_DISTRIBUTOR55,
    ADDRESSES.MERKLE_DISTRIBUTOR56,
    ADDRESSES.MERKLE_DISTRIBUTOR57,
    ADDRESSES.MERKLE_DISTRIBUTOR58,
    ADDRESSES.MERKLE_DISTRIBUTOR59,
    ADDRESSES.MERKLE_DISTRIBUTOR60,
    ADDRESSES.MERKLE_DISTRIBUTOR61,
    ADDRESSES.MERKLE_DISTRIBUTOR62,
    ADDRESSES.MERKLE_DISTRIBUTOR63,
];
export const lotteryHeldDate = "October 21";

// 1st october & 8th october, disabled all buttons
export const isTechnicalWorks = false;
// 8 oct & 16 oct show green background
export const successTechnicalWorks = true;
// to 16th October show global disclaimer
export const showDisclaimer = false;

// export const isTechnicalWorks = Date.now() > 1664625600000 && Date.now() < 1665230400000;// 1st october & 8th october
// export const successTechnicalWorks = Date.now() > 1665230400000 && Date.now() < 1665921600000; // 8 oct & 16 oct
// export const showDisclaimer = Date.now() < 1665921600000; // 16th October
